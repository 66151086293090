<template>
  <div class="article-category">
    <div class="search-wrapper">
      <a-form-model
        :model="entity"
        :layout="'inline'"
        @submit.prevent="handleSearch"
      >
        <a-form-model-item prop="name" label="名称">
          <a-input
            v-model="entity.name"
            type="text"
            placeholder="输入广告位名称搜索"
            allowClear
            @change="
              () => {
                if (entity.name == '') delete entity.name;
              }
            "
          ></a-input>
        </a-form-model-item>

        <a-form-model-item>
          <a-space>
            <a-button
              icon="search"
              :loading="loading"
              type="primary"
              htmlType="submit"
            >
              搜索
            </a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div class="table-wrapper">
      <y-table
        :columns="columns"
        :loading="loading"
        :service="advertisementCategoryService"
        :entity="entity"
        :filters="filters"
        :addable="true"
        :sorter="sorter"
        @add="handleClickAdd"
      >
        <template slot="action" slot-scope="action">
          <a-button type="link" @click="handleSortClick(action.record)"
            >排序</a-button
          >
          <a-button type="link" @click="handleEditClick(action.record)"
            >编辑</a-button
          >
          <a-button type="link" @click="handleDeleteClick(action.record)"
            >删除</a-button
          >
        </template>
      </y-table>
    </div>

    <a-drawer :visible="visible" width="50%" @close="handleClose">
      <a-form-model :model="advertisementCategory" @submit.prevent="handleSave">
        <a-form-model-item label="名称" prop="name">
          <a-input v-model="advertisementCategory.name" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item label="描述" prop="description">
          <a-input
            v-model="advertisementCategory.description"
            allowClear
          ></a-input>
        </a-form-model-item>
        <a-form-model-item label="投放页面地址" prop="path">
          <a-input v-model="advertisementCategory.path" allowClear></a-input>
        </a-form-model-item>
        <a-form-model-item label="类型" prop="type">
          <y-radio
            v-model="advertisementCategory.type"
            :type="'AdvertisementType'"
          ></y-radio>
        </a-form-model-item>
        <a-form-model-item label="排序" prop="sequence">
          <a-input-number
            v-model="advertisementCategory.sequence"
            :defaultValue="1"
            :min="0"
          ></a-input-number>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 8, offset: 4 }">
          <a-space>
            <a-button htmlType="submit" :loading="loading" type="primary">
              保存
            </a-button>
            <a-button :loading="loading" @click="handleClose"> 取消 </a-button>
          </a-space>
        </a-form-model-item>
      </a-form-model>
    </a-drawer>
    <a-modal
      :confirm-loading="loading"
      title="排序"
      :visible="visibleSortModal"
      @cancel="handleSortModalCancel"
      @ok="handleSortModalOk"
    >
      <a-form-model :model="advertisementCategory">
        <a-form-model-item label="排序" prop="sequence">
          <a-input-number
            v-model="advertisementCategory.sequence"
            :defaultValue="1"
            :min="0"
          ></a-input-number>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>

<script>
import Enums from "@/enums";

import AdvertisementCategoryService from "@/services/advertisement-category";

const defaultAdvertisementCategory = {};

export default {
  data() {
    return {
      advertisementCategoryService: AdvertisementCategoryService,
      entity: JSON.parse(JSON.stringify(defaultAdvertisementCategory)),
      filters: {},
      visible: false,
      visibleSortModal: false,
      loading: false,
      sorter:[],
      columns: [
        {
          dataIndex: "name",
          title: "名称",
          sorter: true,
        },
        {
          dataIndex: "description",
          title: "描述",
        },
        {
          dataIndex: "type",
          title: "类型",
          customRender: (text) => {
            return <y-tag type={"AdvertisementType"} value={text}></y-tag>;
          },
          filterMultiple: false,
          filters: Enums.getFiltersByName("AdvertisementType"),
        },
        {
          dataIndex: "path",
          title: "投放页面地址",
        },
        {
          dataIndex: "maximumQuantity",
          title: "最大投放数量",
        },
        {
          dataIndex: "minimumQuantity",
          title: "最小投放数量",
        },
        {
          dataIndex: "advertisements.length",
          title: "存在广告数量",
        },
        {
          dataIndex: "advertisements",
          title: "启用广告数量",
          customRender: (text) => {
            return text ? text.filter((t) => t.enabled).length : 0;
          },
        },
        {
          dataIndex: "sequence",
          title: "权重",
          sorter: true,
          scopedSlots: { customRender: "sequence" },
        },
        {
          dataIndex: "createTime",
          title: "创建时间",
          scopedSlots: { customRender: "dateTime" },
        },
        {
          dataIndex: "creater",
          title: "创建人",
        },
        {
          dataIndex: "updateTime",
          title: "更新时间",
          scopedSlots: { customRender: "dateTime" },
        },
        {
          dataIndex: "updater",
          title: "更新人",
        },
        {
          title: "状态",
          key: "enabled",
          scopedSlots: { customRender: "enabled" },
        },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      // 虚拟模型用于添加和更新
      advertisementCategory: {},
    };
  },
  async mounted() {
    this.handleReset();
  },
  methods: {
    async handleSearch() {
      // 重置entity触发y-table重新获取数据
      this.entity = JSON.parse(JSON.stringify(this.entity));
    },
    handleReset() {
      this.filters = {};
    },
    handleClickAdd() {
      this.advertisementCategory = JSON.parse(
        JSON.stringify(defaultAdvertisementCategory)
      );
      this.visible = true;
    },
    handleClose() {
      this.advertisementCategory = JSON.parse(
        JSON.stringify(defaultAdvertisementCategory)
      );
      this.visible = false;
    },
    handleSortClick(record) {
      this.advertisementCategory = record;
      this.visibleSortModal = true;
    },
    handleEditClick(record) {
      this.advertisementCategory = record;
      this.visible = true;
    },
    async handleDeleteClick(record) {
      try {
        this.loading = true;
        await AdvertisementCategoryService.deleteEntity(record);
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.entity = JSON.parse(JSON.stringify(defaultAdvertisementCategory));
      }
    },
    handleSortModalCancel() {
      this.advertisementCategory = JSON.parse(
        JSON.stringify(defaultAdvertisementCategory)
      );
      this.visibleSortModal = false;
    },
    async handleSortModalOk() {
      try {
        this.loading = true;

        await AdvertisementCategoryService.updateEntity({
          id: this.advertisementCategory.id,
          sequence: this.advertisementCategory.sequence,
        });
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.advertisementCategory = {};
        this.visibleSortModal = false;
        this.entity = JSON.parse(JSON.stringify(defaultAdvertisementCategory));
      }
    },
    async handleSave() {
      try {
        this.loading = true;
        if (!this.advertisementCategory.id) {
          await AdvertisementCategoryService.addEntity(
            this.advertisementCategory
          );
        } else {
          await AdvertisementCategoryService.updateEntity(
            this.advertisementCategory
          );
        }
      } catch (error) {
        this.$message.error(
          error.response ? error.response.data.message : error.message
        );
      } finally {
        this.advertisementCategory = {};
        this.entity = JSON.parse(JSON.stringify(this.entity));
        this.visible = false;
        this.loading = false;
      }
    },
  },
};
</script>
<style lang="less" scoped>
.article-category {
}
</style>
